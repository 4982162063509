import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Modal, Upload, message, Progress, Input, Space } from "antd";
import {
  InboxOutlined,
  ArrowLeftOutlined,
  DeleteOutlined,
  DownloadOutlined,
  SwapOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import type { UploadProps, RcFile } from "antd/es/upload/interface";
import {
  Container,
  ModalContent,
  ButtonContainer,
  CloseButton,
  Content,
  VideoContainer,
  Video,
  Comment,
} from "./style";
import addFile from "../../User/Evaluation/CorePro/UploadVideoSection/images/AddFile-2x.png";
import fileSuccess from "../../User/Evaluation/CorePro/UploadVideoSection/images/FileSuccess-2x.png";
import getSignedUrl, { getCloudFrontSignedUrl } from "../../../util/Downloader";
import Notification from "../NotificationModal";

interface videoType {
  transactionId: string;
  video1: string;
  video1comment: string;
  encodedVideoName1: string;
  video1aws: boolean;
  completed1: number;
  video2: string;
  video2comment: string;
  encodedVideoName2: string;
  video2aws: boolean;
  completed2: number;
  videoId: number;
}

interface VideoModalProps {
  show: boolean;
  video: videoType;
  handleSave: () => void;
  handleClose: () => void;
  handleUploadFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelete: (videoKey: string) => void;
  handleUpdateVideoData: (updatedVideoData: Partial<videoType>) => void;
}

interface States {
  showNotification: boolean;
  message: string;
  notificationStatus: boolean;
  deleteModalVisible: boolean;
  videoToDelete: string | null;
  showLinkInterface1: boolean;
  showLinkInterface2: boolean;
  videoLink1: string;
  videoLink2: string;
  videoComment1: string;
  videoComment2: string;
  tempVideoLink1: string;
  tempVideoComment1: string;
  tempVideoLink2: string;
  tempVideoComment2: string;
}

type Props = VideoModalProps & WithTranslation;

class VideoModal extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showNotification: false,
      message: "",
      notificationStatus: false,
      deleteModalVisible: false,
      videoToDelete: null,
      showLinkInterface1: false,
      showLinkInterface2: false,
      videoLink1: "",
      videoLink2: "",
      videoComment1: "",
      videoComment2: "",
      tempVideoLink1: "",
      tempVideoLink2: "",
      tempVideoComment1: "",
      tempVideoComment2: "",
    };
  }

  componentDidUpdate = (prevProps: VideoModalProps) => {
    const { video1aws, video2aws } = this.props.video;
    if (
      ((!prevProps.video.video1aws && video1aws) ||
        (!prevProps.video.video2aws && video2aws)) &&
      prevProps.video.video1 != null
    ) {
      this.showNotification(
        "Video upload successful, click save to update the database",
        true
      );
    }
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showNotification: true,
    });
    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 3000);
  };

  openDeleteModal = (videoKey: string) => {
    this.setState({
      deleteModalVisible: true,
      videoToDelete: videoKey,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalVisible: false,
      videoToDelete: null,
    });
  };

  confirmDelete = () => {
    const { videoToDelete } = this.state;
    if (videoToDelete) {
      this.props.handleDelete(videoToDelete);
    }
    this.closeDeleteModal();
  };

  handleUploadChange = (videoNumber: number) => {
    const uploadProps: UploadProps = {
      name: "file",
      multiple: false,
      accept: "audio/x-m4a,audio/*,video/*",
      showUploadList: false,
      beforeUpload: async (file: RcFile) => {
        const syntheticEvent = {
          currentTarget: {
            files: [file],
            id: `Upload_${videoNumber}`,
          },
        } as unknown as React.ChangeEvent<HTMLInputElement>;

        try {
          await this.props.handleUploadFile(syntheticEvent);
          await this.props.handleSave();
          message.success(`${file.name} file uploaded and saved successfully.`);
          return false;
        } catch (error: any) {
          message.error(`File upload failed: ${error.message}`);
        }
      },
      onChange(info) {
        const { status } = info.file;
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return uploadProps;
  };

  toggleInterface = (videoNumber: number) => {
    if (videoNumber === 1) {
      this.setState((prevState) => ({
        showLinkInterface1: !prevState.showLinkInterface1,
        tempVideoLink1: "",
        tempVideoComment1: "",
      }));
    } else if (videoNumber === 2) {
      this.setState((prevState) => ({
        showLinkInterface2: !prevState.showLinkInterface2,
        tempVideoLink2: "",
        tempVideoComment2: "",
      }));
    }
  };

  handleSubmitLink = async (videoNumber: number) => {
    const { t } = this.props;
    if (videoNumber === 1) {
      const { tempVideoLink1, tempVideoComment1 } = this.state;
      this.props.handleUpdateVideoData({
        video1: tempVideoLink1,
        video1comment: tempVideoComment1,
      });
      this.setState(
        {
          tempVideoLink1: "",
          tempVideoComment1: "",
        },
        async () => {
          await this.props.handleSave();
          this.showNotification(
            t(
              "user.survey.corePro.video.videoRecording.video1UploadSuccessfulMessage"
            ),
            true
          );
        }
      );
    } else if (videoNumber === 2) {
      const { tempVideoLink2, tempVideoComment2 } = this.state;
      this.props.handleUpdateVideoData({
        video2: tempVideoLink2,
        video2comment: tempVideoComment2,
      });
      this.setState(
        {
          tempVideoLink2: "",
          tempVideoComment2: "",
        },
        async () => {
          await this.props.handleSave();
          this.showNotification(
            t(
              "user.survey.corePro.video.videoRecording.video2UploadSuccessfulMessage"
            ),
            true
          );
        }
      );
    }
  };

  renderUploadInterface = (videoNumber: number, completed: number) => {
    const { t } = this.props;

    const showLinkInterface =
      videoNumber === 1
        ? this.state.showLinkInterface1
        : this.state.showLinkInterface2;

    const tempVideoLink =
      videoNumber === 1 ? this.state.tempVideoLink1 : this.state.tempVideoLink2;

    const tempVideoComment =
      videoNumber === 1
        ? this.state.tempVideoComment1
        : this.state.tempVideoComment2;

    const isValidURL = (url: string) => {
      try {
        new URL(url);
        return true;
      } catch {
        return false;
      }
    };

    if (showLinkInterface) {
      // Link Input Interface
      return (
        <Space direction="vertical" className="w-full">
          {tempVideoLink && !isValidURL(tempVideoLink) && (
            <p style={{ color: "red" }}>
              {" "}
              {t("user.survey.corePro.video.videoRecording.verifyLinkMessage")}
            </p>
          )}
          <Input
            placeholder={t(
              "user.survey.corePro.video.videoRecording.enterVideoLink"
            )}
            value={tempVideoLink}
            onChange={(e) => {
              const value = e.target.value;
              if (videoNumber === 1) {
                this.setState({ tempVideoLink1: value });
              } else if (videoNumber === 2) {
                this.setState({ tempVideoLink2: value });
              }
            }}
          />
          <Input.TextArea
            placeholder={t(
              "user.survey.corePro.video.videoRecording.enterVideoComment"
            )}
            value={tempVideoComment}
            onChange={(e) => {
              const value = e.target.value;
              if (videoNumber === 1) {
                this.setState({ tempVideoComment1: value });
              } else if (videoNumber === 2) {
                this.setState({ tempVideoComment2: value });
              }
            }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSubmitLink(videoNumber)}
            disabled={!tempVideoLink}
          >
            {t("user.survey.corePro.video.videoRecording.submit")}
          </Button>
        </Space>
      );
    } else {
      // File Upload Interface
      return (
        <>
          <Upload.Dragger
            {...this.handleUploadChange(videoNumber)}
            className="w-full"
          >
            <p className="text-lg">
              <InboxOutlined rev={undefined} />
            </p>
            <p className="text-sm text-gray-500">
              {t("user.survey.corePro.video.videoRecording.uploadText")}
            </p>
            {completed > 0 && completed < 100 && (
              <Progress percent={completed} size="small" />
            )}
          </Upload.Dragger>
        </>
      );
    }
  };

  renderSubmittedLinkAndComment = (
    videoLink: string,
    videoComment: string,
    videoNumber: number
  ) => {
    const { t } = this.props;
    return (
      <VideoContainer
        style={{ display: "flex", flexDirection: "column", color: "black" }}
      >
        <div>
          <p>
            <strong>
              {t("user.survey.corePro.video.videoRecording.link")}:
            </strong>{" "}
            <a href={videoLink} target="_blank" rel="noopener noreferrer">
              {videoLink}
            </a>
          </p>
          {videoComment && (
            <p>
              <strong>
                {t("user.survey.corePro.video.videoRecording.comment")}:
              </strong>{" "}
              {videoComment}
            </p>
          )}
        </div>
        <Button
          icon={<DeleteOutlined rev={undefined} />}
          type="primary"
          danger
          onClick={() => this.openDeleteModal(`video${videoNumber}`)}
          style={{ marginTop: "5px" }}
        >
          {t("user.survey.corePro.video.videoRecording.deleteLink")}
        </Button>
      </VideoContainer>
    );
  };

  render() {
    const { show, t, handleClose, video } = this.props;
    const {
      showNotification,
      message,
      notificationStatus,
      deleteModalVisible,
      showLinkInterface1,
      showLinkInterface2,
    } = this.state;

    const {
      video1,
      video1comment,
      encodedVideoName1,
      video1aws,
      video2,
      video2comment,
      encodedVideoName2,
      video2aws,
      completed1,
      completed2,
      transactionId,
      videoId,
    } = video;

    return (
      <Container id="ModalContainer" show={show}>
        <ModalContent id="ModalContent">
          <CloseButton onClick={handleClose}>
            <CloseOutlined
              style={{ fontSize: "24px", color: "white" }}
              rev={undefined}
            />
          </CloseButton>
          <Notification
            show={showNotification}
            message={message}
            status={notificationStatus}
          />
          <Content>
            {videoId === 1 ? (
              <VideoContainer
                style={{ display: "flex", flexDirection: "column" }}
              >
                {this.renderUploadInterface(1, completed1)}
                <Button
                  icon={<SwapOutlined rev={undefined} />}
                  onClick={() => this.toggleInterface(1)}
                  style={{ marginTop: "15px" }}
                >
                  {showLinkInterface1
                    ? t("user.survey.corePro.video.videoRecording.uploadFile")
                    : t("user.survey.corePro.video.videoRecording.enterUrl")}
                </Button>
              </VideoContainer>
            ) : video1aws && encodedVideoName1 !== "" ? (
              <VideoContainer>
                <video
                  src={getCloudFrontSignedUrl(transactionId, encodedVideoName1)}
                  width={"100%"}
                  height={"100%"}
                  controls
                />
                <Button
                  icon={<DeleteOutlined rev={undefined} />}
                  type="primary"
                  danger
                  onClick={() => this.openDeleteModal("video1")}
                  style={{ marginRight: "8px", marginTop: "5px" }}
                >
                  {t("user.survey.corePro.video.videoRecording.delete")}
                </Button>
                <Button
                  icon={<DownloadOutlined rev={undefined} />}
                  type="primary"
                  href={getCloudFrontSignedUrl(
                    transactionId,
                    encodedVideoName1
                  )}
                  download
                  style={{ marginRight: "8px", marginTop: "5px" }}
                >
                  {t("user.survey.corePro.video.videoRecording.download")}
                </Button>
              </VideoContainer>
            ) : video1 !== "" ? (
              this.renderSubmittedLinkAndComment(video1, video1comment, 1)
            ) : (
              <VideoContainer
                style={{ display: "flex", flexDirection: "column" }}
              >
                {this.renderUploadInterface(1, completed1)}
                <Button
                  icon={<SwapOutlined rev={undefined} />}
                  onClick={() => this.toggleInterface(1)}
                  style={{ marginTop: "15px" }}
                >
                  {showLinkInterface1
                    ? t("user.survey.corePro.video.videoRecording.uploadFile")
                    : t("user.survey.corePro.video.videoRecording.enterUrl")}
                </Button>
              </VideoContainer>
            )}

            {videoId === 1 ? (
              <VideoContainer
                style={{ display: "flex", flexDirection: "column" }}
              >
                {this.renderUploadInterface(2, completed2)}
                <Button
                  icon={<SwapOutlined rev={undefined} />}
                  onClick={() => this.toggleInterface(2)}
                  style={{ marginTop: "15px" }}
                >
                  {showLinkInterface2
                    ? t("user.survey.corePro.video.videoRecording.uploadFile")
                    : t("user.survey.corePro.video.videoRecording.enterUrl")}
                </Button>
              </VideoContainer>
            ) : video2aws && encodedVideoName2 !== "" ? (
              <VideoContainer>
                <video
                  src={getCloudFrontSignedUrl(transactionId, encodedVideoName2)}
                  width={"100%"}
                  height={"100%"}
                  controls
                />
                <Button
                  icon={<DeleteOutlined rev={undefined} />}
                  type="primary"
                  danger
                  onClick={() => this.openDeleteModal("video2")}
                  style={{ marginRight: "8px", marginTop: "5px" }}
                >
                  {t("user.survey.corePro.video.videoRecording.delete")}
                </Button>
                <Button
                  icon={<DownloadOutlined rev={undefined} />}
                  type="primary"
                  href={getCloudFrontSignedUrl(
                    transactionId,
                    encodedVideoName2
                  )}
                  download
                  style={{ marginRight: "8px", marginTop: "5px" }}
                >
                  {t("user.survey.corePro.video.videoRecording.download")}
                </Button>
              </VideoContainer>
            ) : video2 !== "" ? (
              this.renderSubmittedLinkAndComment(video2, video2comment, 2)
            ) : (
              <VideoContainer
                style={{ display: "flex", flexDirection: "column" }}
              >
                {this.renderUploadInterface(2, completed2)}
                <Button
                  icon={<SwapOutlined rev={undefined} />}
                  onClick={() => this.toggleInterface(2)}
                  style={{ marginTop: "15px" }}
                >
                  {showLinkInterface2
                    ? t("user.survey.corePro.video.videoRecording.uploadFile")
                    : t("user.survey.corePro.video.videoRecording.enterUrl")}
                </Button>
              </VideoContainer>
            )}
          </Content>

          <Modal
            title={t(
              "user.survey.corePro.video.videoRecording.confirmDeletion"
            )}
            visible={deleteModalVisible}
            onOk={this.confirmDelete}
            onCancel={this.closeDeleteModal}
            okText={t("user.survey.corePro.video.videoRecording.confirm")}
            cancelText={t("user.survey.corePro.video.videoRecording.cancel")}
          >
            <p>
              {" "}
              {t(
                "user.survey.corePro.video.videoRecording.deleteModalConfirmMessage"
              )}
            </p>
          </Modal>
        </ModalContent>
      </Container>
    );
  }
}

export default withTranslation()(VideoModal);
