import AWS from "aws-sdk";
import { Signer } from "aws-sdk/clients/cloudfront"; // Import CloudFront Signer if signed URLs are needed

AWS.config.region = process.env.REACT_APP_AWS_UPLOADER_S3_REGION;

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID ?? "",
});

const getSignedUrl = (folder: string, name: string) => {
  const s3 = new AWS.S3({
    region: process.env.REACT_APP_AWS_UPLOADER_S3_REGION,
  });
  // const signedUrlExpireSeconds = 60 * 5 // your expiry time in seconds.

  const url = s3.getSignedUrl("getObject", {
    Bucket: process.env.REACT_APP_AWS_UPLOADER_S3_BUCKET as string,
    Key: `${folder}/${name}`,
    // Expires: signedUrlExpireSeconds
  });
  return url;
};

export const getCloudFrontSignedUrl = (folder: string, name: string) => {
  // CloudFront distribution domain
  const cloudFrontDomain = process.env.REACT_APP_CLOUDFRONT_DOMAIN ?? "";

  // Construct the URL
  const fileUrl = `https://${cloudFrontDomain}/${folder}/${name}`;

  // Return the URL directly if public access is fine
  return fileUrl;

  // Uncomment this if you need a signed URL for secure access
  /*
  const signer = new Signer(
    process.env.REACT_APP_CLOUDFRONT_KEY_PAIR_ID!, // CloudFront Key Pair ID
    process.env.REACT_APP_CLOUDFRONT_PRIVATE_KEY! // Path to the private key
  );

  const signedUrl = signer.getSignedUrl({
    url: fileUrl,
    expires: Math.floor(Date.now() / 1000) + 60 * 5, // 5 minutes expiration
  });

  return signedUrl;
  */
};

export default getSignedUrl;
