import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ProTable } from "@ant-design/pro-components";
import { ConfigProvider, Input } from "antd";
import api_address from "../../../constants/config";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { GET_DATE_TIME_STRING_FROM_TIMESTAMP } from "../../../util/common";
import { JS_COOKIE } from "util/auth";
import { Container, AdminTableContainer } from "../styles";
import enUS from "antd/es/locale/en_US";
import NotificationModal from "../../common/NotificationModal";

interface FeedbackType {
  id: string;
  userid: string;
  score: string;
  resultId: string;
  product: string;
  maxscore: string;
  comment: string;
  createdAt: string;
}

interface SorterState {
  field: string;
  order: "ascend" | "descend" | undefined;
}

const Feedback: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [notificationStatus, setNotificationStatus] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [sorter, setSorter] = useState<SorterState | null>(null);
  const [feedbackData, setFeedbackData] = useState<FeedbackType[]>([]);
  const [filteredData, setFilteredData] = useState<FeedbackType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<{}>({});

  const getFeedbackData = async (
    page: number = 1,
    pageSize: number = LIMIT
  ) => {
    setLoading(true);
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { data } = await axios.get(api_address + "api/admin/feedback", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sortedData = sortData(data.feedback, sorter);
      setFeedbackData(sortedData);
      setFilteredData(sortedData);
      setTotal(data.rowCount);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getFeedbackData();
  }, []);

  useEffect(() => {
    applyFiltersAndSort();
  }, [filters, sorter, feedbackData, searchText]);

  const applyFiltersAndSort = () => {
    let result = [...feedbackData];

    if (searchText && searchText.trim() !== "") {
      const searchLower = searchText.toLowerCase();
      result = result.filter(
        (item) =>
          item.id?.toString().includes(searchText) || // Case-sensitive exact match
          item.userid?.toString().includes(searchText) ||
          item.resultId?.toString().includes(searchText)
      );
    }

    result = sortData(result, sorter);
    setFilteredData(result);
    setTotal(result.length);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const newSorter: SorterState = {
      field: sorter.field,
      order: sorter.order,
    };
    setSorter(newSorter);
    setFilters(filters);
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
  };

  const sortData = (
    data: FeedbackType[],
    currentSorter: SorterState | null
  ) => {
    if (!currentSorter || !currentSorter.field) return data;

    return [...data].sort((a, b) => {
      const aValue = a[currentSorter.field];
      const bValue = b[currentSorter.field];

      if (currentSorter.order === "ascend") {
        return (aValue || "") > (bValue || "") ? 1 : -1;
      }
      return (aValue || "") < (bValue || "") ? 1 : -1;
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: t("admin.feedback.id"),
      dataIndex: "id",
      sorter: true,
    },
    {
      title: t("admin.user.id"),
      dataIndex: "userid",
      sorter: true,
      render: (text: string) => (
        <a onClick={() => history.push(`/admin/user/edit/${text}`)}>{text}</a>
      ),
    },
    {
      title: t("admin.result.id"),
      dataIndex: "resultId",
      sorter: true,
    },
    {
      title: t("admin.feedback.product"),
      dataIndex: "product",
      sorter: true,
      render: (text: string) => t(`admin.product.${text}`),
    },
    {
      title: t("admin.feedback.score"),
      dataIndex: "score",
      sorter: true,
      render: (text: string, record: FeedbackType) =>
        `${text} / ${record.maxscore}`,
    },
    {
      title: t("admin.feedback.comment"),
      dataIndex: "comment",
      ellipsis: true,
    },
    {
      title: t("admin.user.createdAt"),
      dataIndex: "createdAt",
      sorter: true,
      render: (text: string) => GET_DATE_TIME_STRING_FROM_TIMESTAMP(text),
    },
  ];

  return (
    <ConfigProvider locale={enUS}>
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <AdminTableContainer>
          <Input.Search
            placeholder={"feedback ID, User ID, Product"}
            onChange={handleSearch}
            style={{ marginBottom: 16 }}
          />
          <ProTable<FeedbackType>
            columns={columns}
            loading={loading}
            dataSource={filteredData}
            rowKey="id"
            pagination={{
              showQuickJumper: true,
              pageSize: pageSize,
              current: currentPage,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "25", "50", "100"],
            }}
            search={false}
            dateFormatter="string"
            headerTitle={t("admin.feedback.title")}
            toolBarRender={false}
            onChange={handleTableChange}
          />
        </AdminTableContainer>
      </Container>
    </ConfigProvider>
  );
};

export default Feedback;
