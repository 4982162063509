import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import api_address from "../../../constants/config";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { GET_DATE_TIME_STRING_FROM_TIMESTAMP } from "../../../util/common";
import { JS_COOKIE } from "util/auth";
import { Container, AdminTableContainer } from "../styles";
import ReferralLinkModal from "../../common/ReferralLinkModal";
import { PRODUCTS } from "../../../constants/common";

interface CouponType {
  id: string;
  couponCode: string;
  redeemBy: string;
  currentRedemption: string;
  maxRedemption: string;
  applyTo: string[];
  applyAll: boolean;
}

interface SorterState {
  field: string;
  order: "ascend" | "descend" | undefined;
}

const Coupon: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [couponData, setCouponData] = useState<CouponType[]>([]);
  const [filteredData, setFilteredData] = useState<CouponType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sorter, setSorter] = useState<SorterState | null>(null);
  const [filters, setFilters] = useState<{}>({});
  const [searchText, setSearchText] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showReferralLinkModal, setShowReferralLinkModal] =
    useState<boolean>(false);

  const getCouponData = async (page: number = 1, pageSize: number = LIMIT) => {
    setLoading(true);
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const offset = (page - 1) * pageSize;
      const { data } = await axios.get(api_address + "api/admin/coupon", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sortedData = sortData(data.coupon, sorter);
      setCouponData(sortedData);
      setFilteredData(sortedData);
      setTotal(data.rowCount);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCouponData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [filters, sorter, couponData, searchText]);

  const applyFiltersAndSort = () => {
    let result = [...couponData];

    if (searchText) {
      result = result.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }

    Object.entries(filters).forEach(([key, values]) => {
      if (values && values.length > 0) {
        result = result.filter((item) => {
          if (key === "applyTo") {
            return values.some((val) => item.applyTo.includes(val));
          }
          return true;
        });
      }
    });

    result = sortData(result, sorter);

    setFilteredData(result);
    setTotal(result.length);
  };

  const renderDate = (text: string) => {
    if (!text || text === "-" || text.includes("NaN")) {
      return "-";
    }
    return GET_DATE_TIME_STRING_FROM_TIMESTAMP(text);
  };

  const sortData = (data: CouponType[], currentSorter: SorterState | null) => {
    if (!currentSorter) return data;

    return [...data].sort((a, b) => {
      if (currentSorter.order === "ascend") {
        return a[currentSorter.field] > b[currentSorter.field] ? 1 : -1;
      } else if (currentSorter.order === "descend") {
        return a[currentSorter.field] < b[currentSorter.field] ? 1 : -1;
      }
      return 0;
    });
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const newSorter: SorterState = {
      field: sorter.field,
      order: sorter.order,
    };
    setSorter(newSorter);
    setFilters(filters);
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
    getCouponData(pagination.current, pagination.pageSize);
    applyFiltersAndSort();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const productFilters = Object.keys(PRODUCTS).map((key) => ({
    text: PRODUCTS[key].replace(/([A-Z])/g, " $1").trim(),
    value: PRODUCTS[key],
  }));

  const columns = [
    {
      title: t("admin.coupon.id"),
      dataIndex: "id",
      sorter: true,
    },
    {
      title: t("admin.coupon.couponCode"),
      dataIndex: "couponCode",
      sorter: true,
    },
    {
      title: t("admin.coupon.currency"),
      dataIndex: "currency",
      sorter: true,
    },
    {
      title: t("admin.coupon.redemption"),
      dataIndex: "redemption",
      sorter: true,
      render: (_, record: CouponType) =>
        `${record.currentRedemption} / ${record.maxRedemption}`,
    },
    {
      title: t("admin.coupon.applyTo"),
      dataIndex: "applyTo",
      render: (applyTo: string[]) => applyTo.join(", "),
      filters: productFilters,
    },
    {
      title: t("admin.coupon.redeemBy"),
      dataIndex: "redeemBy",
      sorter: true,
      render: renderDate,
    },
  ];

  const handleOpenReferralLinkModal = () => {
    setShowReferralLinkModal(true);
  };

  const handleCloseReferralLinkModal = () => {
    setShowReferralLinkModal(false);
  };

  return (
    <ConfigProvider locale={enUS}>
      <AdminTableContainer>
        <Input.Search
          placeholder={t("admin.coupon.couponCode")}
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
        <ProTable<CouponType>
          columns={columns}
          dataSource={filteredData}
          loading={loading}
          rowKey="id"
          pagination={{
            showQuickJumper: true,
            total: total,
            pageSize: pageSize,
            current: currentPage,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "25", "50", "100"],
          }}
          search={false}
          dateFormatter="string"
          headerTitle={t("admin.coupon.title")}
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSelectedRowKeys(selectedRowKeys);
            },
          }}
          toolBarRender={() => [
            <Button
              key="create"
              onClick={() => history.push("/admin/coupon/create/0")}
            >
              {t("admin.coupon.create")}
            </Button>,
            selectedRowKeys.length === 1 && (
              <Button
                key="edit"
                onClick={() =>
                  history.push(`/admin/coupon/edit/${selectedRowKeys[0]}`)
                }
              >
                {t("admin.coupon.edit")}
              </Button>
            ),
          ]}
          onChange={handleTableChange}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                history.push(`/admin/coupon/edit/${record.id}`);
              },
            };
          }}
        />
      </AdminTableContainer>
      <ReferralLinkModal
        showReferralLinkModal={showReferralLinkModal}
        handleCloseReferralLinkModal={handleCloseReferralLinkModal}
      />
    </ConfigProvider>
  );
};

export default Coupon;
