import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import EvalProgressBar from "../ProgressBar/EvalProgressBar";
import Cookies from "js-cookie";
import axios, { AxiosRequestConfig } from "axios";
import api_address from "../../../../constants/config";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  EVAL_STEPS,
  PRODUCTS,
  PRODUCTS_WITH_CHILD_INFO_ONLY,
} from "../../../../constants/common";
import { Background, ButtonContainer, Container } from "../styles";
import ChangeBrowserModal from "../../../common/ChangeBrowserModal";
import { PRODUCTS_WITH_VIDEO_EVAL } from "constants/common";
import CoreIntro from "./CoreIntro";
import CoreProIntro from "./CoreProIntro";
import BJCHIntro from "./BJCHIntro";
import { ShowErrNextButton } from "styles";
import { GET_TITLE_BAR } from "util/common";
import ChildInfoOnlyIntro from "./ChildInfoOnlyIntro";

interface IntroProps {
  handleUpdateCurrentStep: (data: string) => void;
  product: string;
  transactionId: string;
}

interface IntroState {
  showModal: boolean;
}

type Props = IntroProps & WithTranslation & RouteComponentProps;

class Intro extends Component<Props, IntroState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount = () => {
    const { t, product } = this.props;
    document.title = GET_TITLE_BAR(t, "introduction", true);
    if (product !== PRODUCTS.BJCH_Questionnaire) {
      const isWeiXin = navigator.userAgent.includes("MicroMessenger");
      this.setState({ showModal: isWeiXin });
    }
  };

  handleNext = async () => {
    try {
      const token = Cookies.get("token");
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      } as AxiosRequestConfig;
      const { transactionId } = this.props;
      const apiData = {
        transactionId,
      };
      const res = await axios.post(
        api_address + "api/results/intro",
        apiData,
        headers
      );
      const { status, next } = res.data;
      if (status) {
        this.props.handleUpdateCurrentStep(next);
      }
    } catch (err: any) {
      console.error(err.message);
    }
  };

  getIntro = () => {
    const { product } = this.props;
    if (product === PRODUCTS.BJCH_Questionnaire) {
      return <BJCHIntro />;
    } else if (PRODUCTS_WITH_VIDEO_EVAL.includes(product)) {
      return <CoreProIntro product={product} />;
    } else if (PRODUCTS_WITH_CHILD_INFO_ONLY.includes(product)) {
      return <ChildInfoOnlyIntro />;
    } else {
      return <CoreIntro />;
    }
  };

  render(): JSX.Element {
    const { showModal } = this.state;
    const { t, product } = this.props;
    return (
      <Background>
        <ChangeBrowserModal show={showModal} />
        <EvalProgressBar step={EVAL_STEPS.basicIntro} product={product} />
        <Container>
          {this.getIntro()}
          <ButtonContainer>
            <ShowErrNextButton onClick={this.handleNext}>
              {t("user.survey.core.questionnaire.intro.corePro.4.continue")}
            </ShowErrNextButton>
          </ButtonContainer>
        </Container>
      </Background>
    );
  }
}

export default withRouter(withTranslation()(Intro));
